.rdt_TableHeader {
    display: none !important
}

.table-filter-wrapper {
    text-align: center;
}

.highlighted {
    background-color: skyblue;
    transition: background-color 1s linear;
}

.table-filter {
    font-size: larger;
    padding: .3em;
    margin: 0em 1em;
}

.clear-filter {
    font-size: larger;
    margin-top: -.3em;
}

.btn-grey {
    background-color: grey !important;
    border: 1px solid grey !important;
}