.fetched {
	border: solid 1px #bbb;
	font-size:small;
	padding: 3px;
	margin-left: 6px;
}
.fetched-Uncalled {
	background-color: yellow;	
}

.fetched-OK {
	background-color: lightgreen;
}

.fetched-Calling {
	background-color: lightblue;
}


.fetched-Failed {
	background-color: coral;
}
