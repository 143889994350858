.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}

.ServiceBlock {
    font-size: small;
    border: solid 1px #ccc;
    padding: 2px;
    color: black;
}

.ServiceStatusPENDING {
    padding: 0 2px 0 2px;
    background-color:lightblue;
}
.ServiceStatusERROR {
    padding: 0 2px 0 2px;
    background-color: red;
}
.ServiceStatusPASS {
    padding: 0 2px 0 2px;
    background-color:lightgreen;
}
.ServiceStatusFAIL {
    padding: 0 2px 0 2px;
    background-color:darkred;
    color: white;
}
.ColHeader {
    text-align: center;
    border-right: solid 1px black;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    padding: 12px 0 12px 0;
}
.Col1Header {
    background-color: #eef;
    border-left: solid 1px black;
}
.Col2Header {
    background-color: #ddf;
}
.Col3Header {
    background-color: #ccf;
}
.Col4Header {
    background-color: #bbf;
}
.ColData {
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}
.Col1Data {
    background-color: #eef;
    border-left: solid 1px black;
}
.Col2Data {
    background-color: #ddf;
}
.Col3Data {
    background-color: #ccf;
}
.Col4Data {
    background-color: #bbf;
}
.MyTinyText, .TaxMainTitle, .TaxSubTitle
{
    font-size:x-small;
}
.TaxMainTitle
{
    font-weight: bold;
    text-decoration: underline;
}
.TaxSubTitle
{
    text-decoration: underline;
}
.ForceNumericLI {   /* MRCViewer hides number */
    list-style-type: decimal;
} 