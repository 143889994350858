/* ajc interfered with other selects
.Module-reinsurance, select {
    padding: .5rem;
    margin: 0 .5rem;
    cursor: pointer;
}
*/

.modal i {
    color: grey;
}

/* ajc was causing McGill EOC Popup alignment problem
.modal .col:nth-child(2) {
    padding: 1.7rem 1rem
}

.modal .col:nth-child(3) {
    padding: 1rem
}
*/

.modal .victoria {
    text-align: center;
    font-size: 3rem;
    display: block;
    float: left;
    margin-left: 0.85rem;
    font-weight: bold;
    color: blue;
}

.modal .line {
    border-right: 3px solid blue;
}