.CDRadioButton {
	float:left;
	border: solid 2px blue;
	border-radius: 6px;
	padding: 6px;
	text-align: center;
	margin-right: 6px;
	margin-bottom: 6px;
	cursor: pointer;
}

.CDSelected {
	background-color: #aff;
}