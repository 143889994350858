.InputTA {
    width: 100%;
    height: 300px;
    font-size: small;
}

.ItemTitle {
    font-weight: bold;
}

.NegativeFigure {
    color: red;
}

.CarrierLogo {
    width: 120px;
    height: 120px;
}

.FeedbackAlert {
    padding: 2px 6px 2px 6px;
    font-size: small;
}
.FeedbackNone {
    border: solid 1px black;
    background-color: white;
    color: black;
}
.FeedbackAgree {
    border: solid 1px green;
    background-color: green;
    color: white;
}
.FeedbackDisagree {
    border: solid 1px red;
    background-color: red;
    color: white;
}

.FeedbackAlert:hover {
    border: solid 1px blue;
    background-color: blue;
    color: white;   
    cursor: pointer;
}
.DlgSpacer {
    margin-top: 12px;
    border-top: dashed 2px lightgray;
    margin-bottom: 12px;
}
.DlgTextArea {
    width: 100%;
    height: 120px;
}

.StrikeThrough {
    text-decoration: line-through;
}
.Italics {
    font-style: italic;   
}
/*
.DebugBackground {
    background-color: lightblue;
}
*/