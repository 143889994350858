.Pill, .PillDisabled {
    
    color: white;
    font-size: small;
    border-radius: 5px;
    padding: 5px;
    margin-right: 6px;
    margin-top: 6px;
    float: left;
}

.Pill {
    background-color: coral;
}

.PillDisabled
{
    background-color: #eee;
}

.QuietBorder
{
    background-color: #eee;
    border: solid 1px #ddd;
    padding: 2px;
    margin-top: 2px;
}

.Width100
{
    width: 100px;
}