.Modify-text, .Modify-textarea {
    margin-top: .5em;
    width: 100%;
    padding: .5em;
}

.Option {}

.Option-title {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
    font-size: 1.2rem;
}

.Col-select {
    border: 1px solid #007bff;
    margin: 1rem 0.5rem;
    padding: 0rem;
    overflow: hidden;
    color: #007bff;
}

.Col-select:hover, .Col-select.selected {
    cursor: pointer;
    background-color: #007bff;
}

.Col-select:hover, .Col-select.selected {
    color: white;
}

.Col-select .header {
    display: block;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border-bottom: 1px solid #007bff;
}

.Col-select:hover .header, .Col-select.selected .header {
    border-bottom: 1px solid white;
}

.Col-select .body {
    padding: 0.5rem 1rem;
}

.Col-select:nth-child(1) {
    border-radius: 1rem 0rem 0rem 1rem;
    margin-right: 0rem;
}

.Col-select:nth-child(2) {
    border-radius: 0rem 1rem 1rem 0rem;
    margin-left: 0rem;
    border-left: none;
}