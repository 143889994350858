.JSONViewer {
    width: 100%;
    height: 1000px;
    font-size: small;
}

.OneRule {
    border: solid 1px lightgray;
    border-radius: 6px;
    padding: 6px;
}