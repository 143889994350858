.data-table {
    display: flex;
    justify-content: left;
}

.table-wrapper {
    width: 50%;
}

.input-field {
    height: 36px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 3px 16px;
}

.input-field:hover {
      cursor: pointer;
}

/* .clear-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 35px;
    text-align: center;
} */

.Highlight1 {
    background-color: pink;
}

.TeamDetail {
    font-size: small;
    margin: 0 0 6px 60px;
}