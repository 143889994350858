.TeamSelected {
	background-color: lightblue;
}
.Indented1 {
	margin-left: 36px;
}
.Indented2 {
	margin-left: 72px;
}

.FilePickerPanel {
	border: solid 1px #ccc;
	background-color: #eee;
	padding: 6px;
	border-radius: 6px;
}

.Right30 {
	margin-right: 30px;
}

.InformationTextArea {
	width: 100%;
	height: 80px;
}
