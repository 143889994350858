.PassColour {
	background-color: lightgreen;
}
.FailColour {
	background-color: lightpink;
}
.OptionalFailColour {
	background-color: lightgoldenrodyellow;
}
.OptionalAbsentColour {
	background-color: lightgray;
	text-decoration: line-through;
}
.ConditionalAbsentColour {
	background-color: gray;
	text-decoration: line-through;
}

.NotApplicableColour {
	background-color: lightblue;
}

.SummaryTestSpan {
	padding-left: 2px;
	padding-right: 2px;
	margin-right: 2px;
}
.HeaderRow {
	background-color: #222;
	color: white;
}

.SummaryRow {
	border-bottom: solid 1px #333;
}
.SummaryRow, .TestGridRow {
	font-size: small;
}

.CDRBlob {
	border-radius: 6px;
	padding: 6px;
	
	margin-bottom: 6px;
}

.CDRBlobImplemented {
	background-color: #ddf;
	border: solid 1px black;
}
.CDRBlobPartiallyImplemented {
	background-color: #bbf;
	border: solid 1px black;
}
.CDRBlobNotYetImplemented {
	background-color: eee;
	border: dotted 1px gray;
	color: #aaa;
}

.CDRBlob H3 {
	font-size: 1.4em;
}

.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}