.SectPicker:hover
{
	background-color: lightskyblue;
}
.SectSelected, .SectPicker {
	margin-top: 12px;
	
	border-radius: 6px;
	padding: 6px;
}

.SectSelected {
	border: solid 3px blue;
}

.SectPicker {
	border: solid 3px skyblue;
}

.ShowMe {
	background-color: skyblue;
	font-size: small;
	margin-left: 12px;
	padding-left: 12px;
	padding-right: 12px;
	color: white;
}
.HideMe {
	display: none;
}