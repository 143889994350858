h4 {
    display: block;
    margin-top: 0.3em;
}

.Search-options {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: .5rem;
    width: 40rem;
    background: whitesmoke;
    margin: 1rem auto;
}

.Search-form {
    width: 80%;
    margin: auto;
    text-align: center;
    white-space: nowrap;
}

.submit-btn {
    min-width: 10em;
}

.custom-range {
    width: 80%;
    height: 0em;
}