.UMRList {
	width: 100%;
	height: 100px;
}

.NormalCell {
	
}

.ErrorCell {
	background-color: lightpink;
}

.SuccessCell {
	background-color: lightgreen;
}