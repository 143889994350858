/* bullets handled manually, but keep using ul/ol for better copy/paste*/

:root {
    --primary: #007fff;
}

ul {
    list-style-type: none;
}

ol {
    list-style-type: none;
}

*[data-bullet] {
    position: relative;
}

*[data-bullet]:before {
    content: attr(data-bullet);
    position: absolute;
    left: -20px;
    transform: translateX(-100%);
}

.cell-text {
    resize: none;
    border: none;
    padding: 5px;
}

.cell {
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    border: 1px solid black;
    vertical-align: top;
}

.cell-div {
    white-space: pre-line;
    overflow-y: hidden;
}

.cell-div:hover {
    overflow-y: auto;
}

.line-item-table {
    font-size: 10px;
    table-layout: fixed;
}

.indented {
    margin-left: 40px;
}

.para {
    min-height: 8px;
    margin-bottom: 6px;
}

.hide-bullet {
    list-style-type: none;
    counter-increment: none;
}

.preserve-spaces {
    white-space: pre-wrap;
}

.scrollX {
    overflow-x: auto;
}

.line-item-table-container {
    margin-bottom: 10px;
}

/* Scrollbar always visible on Mac */

.line-item-table-container::-webkit-scrollbar {
    -webkit-appearance: none;
}
/*
.line-item-table-container::-webkit-scrollbar:vertical {
    width: 11px;
}
*/
.line-item-table-container::-webkit-scrollbar:horizontal {
    height: 11px;
}

.line-item-table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
/* end scrollbar */

.ph-highlight {
    color: var(--primary);
}

.contract {
    font-size: 85%;
}

.mrc-section-heading, .line-item-name {
    font-weight: bold;
}

.mrc-line-item {
    padding: .75rem 1.25rem;
    border-bottom: 2px solid #eee;
}