.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}

.PayloadTA {
    width: 100%;
    height: 600px;
    font-size: small;
}